// USA
export const locale = {
    lang: 'ar',
    data: {

        MENU: {
            NEW: 'جديد',
            SHORT_ACCESS: 'وصول مختصر',
            ADD_NEW: 'أضف جديد',
            APPS: 'تطبيقات',
            DASHBOARD: 'اللوحة الرئيسية',
            STATIONS: 'محطات',
            HOME_STATIONS: 'محطات منزلية',
            USERS: 'مستخدمين',
            DRIVERS: 'سائقين',
            WALLETS: 'محافظ المستخدمين',
            STAND: 'قياس',
            LOGOUT: 'خروج',
            ANALS: 'التحليلات والتقارير',
            LANG: {
                AR: 'عربي',
                EN: 'English',
            },
            SETTINGS: {
                TITLE: 'إعدادات',
                MENU: {
                    ADMINS: 'مشرفين',
                    ROLES: 'صلاحيات',
                }
            },
            DATA_TABLE: 'جدول البيانات',
            VEHICLES: 'مركبات',
            BUS: 'الأتوبيسات',
            MODELS: 'أنواع',
            COMPANIES: 'شركات',
            REPORTS: 'تقارير',
            CompoundReport: 'تقارير مناطق',
            transactionReports: 'تقارير عمليات الشحن',
            inProgressTransaction: 'عمليات شحن جارية',
            stationReports: 'تقارير المحطات',
            usersReports: 'تقارير المستخدمين',
            walletReports: 'تقارير المحافظ ',
            vehiclesReports: 'تقارير المركبات',
            BUS_MODELS: 'موديل الأتوبيس',
            BUS_COMPANIES: 'نوع الأتوبيس',
            driversReports: 'تقارير السائقين',
            connectorReports: 'تقرير الشواحن',
            GENERAL: 'الرئيسية'
        },

        VALIDATION: {
            INVALID: '{{name}} غير صحيح',
            REQUIRED: '{{name}} مطلوب',
            REQUIRED_F: '{{name}} مطلوب',
            MIN_LENGTH: '{{name}} لا يقل عدد حروفه عن {{min}}',
            AGREEMENT_REQUIRED: 'يجب قبول الشروط والأحكام أولاً',
            NOT_FOUND: 'المطلوب {{name}} غير موجود',
            INVALID_LOGIN: 'اسم المستخدم وكلمة المرور غير صحيحين',
            REQUIRED_FIELD: 'حقل مطلوب',
            MIN_LENGTH_FIELD: 'الحد الأقصى لعددالحروف:',
            MAX_LENGTH_FIELD: 'الحد الأقصى لعددالحروف:',
            INVALID_FIELD: 'هذا الحقل غير صحيح',
        },

        STATISTICS: {
            name: 'إحصاءات',
            visitors_count: '',
            link: 'افتح قائمة {{title}}'
        },

        MENUS: {
            TEMPLATE: {
                TITLE: 'تصميم',
                MENU: {
                    TEMPLATE: 'تصميم',
                },
            },
            STATIONS: {
                NETWORKS: 'شبكات',
                DISTRICTS: 'مناطق',
                FACILITIES: 'خدمات',
                PLUGTYPES: 'نوع الشاحن',
                COUNTRIES: 'بلاد',
                CHARGING: 'شحن',
                HOME_STATIONS: 'محطات منزلية',

            },
            USERS: {
                USERS: 'مستخدمين'
            }
        },

        AUTH: {
            GENERAL: {
                OR: 'أو',
                SUBMIT_BUTTON: 'ارسل',
                NO_ACCOUNT: 'لا تملك حساب بعد؟',
                SIGNUP_BUTTON: 'أنشئ حساب',
                FORGOT_BUTTON: 'نسيت كلمة المرور؟',
                BACK_BUTTON: 'عودة',
                PRIVACY: 'خصوصية',
                LEGAL: 'قانون',
                CONTACT: 'اتصل بنا',
            },
            LOGIN: {
                TITLE: 'دخول للحساب',
                BUTTON: 'ادخل',
            },
            FORGOT: {
                TITLE: 'نسيت كلمة المرور؟',
                DESC: 'أدخل بريدك الإلكتروني المسجل لدينا ليمكنك تغيير كلمة السر',
                SUCCESS: 'تم تحديث حسابك بنجاح'
            },
            REGISTER: {
                TITLE: 'أنشئ حساب',
                DESC: 'أدخل بياناتك اللازمة لإنشاء حساب',
                SUCCESS: 'لقد تم إنشاء حسابك بنجاح!'
            },
            INPUT: {
                EMAIL: 'بريد إلكتروني',
                FULLNAME: 'الاسم الكامل',
                PASSWORD: 'كلمة المرور',
                CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
                USERNAME: 'اسم المستخدم'
            },
        },

        Components: {
            item_per_page: 'عنصر بكل صفحة',
            filter_by: 'تنقية',
            actions: 'أوامر',
            all: 'الكل',
            marked: 'مختار',
            active: 'نشط',
            is_active: 'غير نشط',
            items: 'عناصر',
            id: 'رقم المسلسل',
            options: 'اختيارات',
            Edit: 'تعديل',
            Details: 'تفاصيل',
            Delete: 'مسح',
            Close: 'غلق',
            Search: 'بحث',
            ItemNotFound: 'لا توجد نتائج لهذا البحث',
            Images: 'صور',
            status: 'حالة',
            date: 'تاريخ',
            Select: 'اختر',
            export: 'استكشف',
            no_image: 'لا توجد صور بعد',
            Accept: 'موافق',
            Reject: 'مرفوض',
            Pending: 'قيد الانتظار',
            export_excel: 'تحميل اكسل',
            export_pdf: 'تحميل PDF',

            TEMPLATE: {
                name: 'قالب',
                single: 'قالب',
                columns: {
                    TEMPLATE: 'قالب',
                }
            },

            STATIONS: {
                name: 'محطات',
                single: 'محطة',
                columns: {
                    description: 'وصف',
                    name: 'الاسم',
                    status: 'الحالة',
                    price: 'السعر',
                    rate: 'المعدل',
                    facilities: 'خدمات',
                    district: 'مناطق',
                    network: 'شبكات',
                    street_location: 'العنوان',
                    charger_type: 'نوع الشاحن',
                    home_charger: 'شاحن منزلي',
                    public_charger: 'شاحن عام',
                    stations_time_availability: 'مواعيد الشحن المتاحة',
                    not_available: 'غير متاح',
                    available_24_hour: 'متاح ٢٤ ساعة',
                    every_day_from_to_time: 'كل يوم من الساعة إلى',
                    stations_time_availability_from: 'وقت متاح من الساعة',
                    stations_time_availability_to: 'وقت متاح إلى الساعة',
                    station_plug_type: 'نوع الشاحن',
                    km_hours: 'كيلووات/ساعة',
                    charge_box_id: 'Charge box id',

                    id_tag: 'Ocpp tag 1',
                    id_tag2: 'Ocpp tag 2',
                }
            },
            HOME_STATIONS: {
                name: 'محطات منزلية',
                single: 'محطة منزلية',
                columns: {
                    description: 'وصف',
                    name: 'الاسم',
                    status: 'الحالة',
                    price: 'السعر',
                    rate: 'المعدل',
                    facilities: 'خدمات',
                    district: 'مناطق',
                    network: 'شبكات',
                    street_location: 'العنوان',
                    charger_type: 'نوع الشاحن',
                    home_charger: 'شاحن منزلي',
                    public_charger: 'شاحن عام',
                    stations_time_availability: 'مواعيد الشحن المتاحة',
                    not_available: 'غير متاح',
                    available_24_hour: 'متاح ٢٤ ساعة',
                    every_day_from_to_time: 'كل يوم من الساعة إلى',
                    stations_time_availability_from: 'وقت متاح من الساعة',
                    stations_time_availability_to: 'وقت متاح إلى الساعة',
                    station_plug_type: 'نوع الشاحن',
                    km_hours: 'كيلومتر/ساعة',
                    charge_box_id: 'Charge box id',

                    id_tag: 'Ocpp tag 1',
                    id_tag2: 'Ocpp tag 2',
                }
            },
            NETWORKS: {
                name: 'شبكات',
                single: 'شبكات',
                columns: {
                    description: 'الوصف',
                    name: 'الاسم',
                }
            },
            FACILITIES: {
                name: 'خدمات',
                single: 'خدمات',
                columns: {
                    name: 'الاسم',
                }
            },
            PLUGTYPES: {
                name: 'أنواع الشواحن',
                single: 'نوع الشاحن',
            },
            MODELS: {
                name: 'أنواع',
                single: 'النوع',
                bus_name: 'أنواع',
                bus_single: 'النوع',
            },
            COMPANIES: {
                name: 'شركات',
                single: 'شركة',
                bus_name: 'شركات',
                bus_single: 'شركة',
            },
            COUNTRIES: {
                name: 'دول',
                single: 'دولة',
                columns: {
                    country_code: 'رقم تعريف الدولة'
                }
            },
            USERS: {
                name: 'مستخدمين',
                single: 'مستخدم',
                sewedy_name: 'سائقين',
                sewedy_single: 'سائق',
                columns:
                    {
                        trips: 'رحلات',
                        name: 'الاسم',
                        no_trips: 'لا توجد رحلان',
                        from_location: 'من',
                        to_location: 'إلى',
                        favorite_stations: 'المحطات المفضلة',
                        no_favorite_stations: 'لا توجد محطات مفضلة',
                        direction: 'اتجاه',
                        open_direction: 'افتح الاتجاه'
                    }
            },
            WALLETS: {
                name: 'محافظ',
                single: 'محفظة',
                columns:
                    {
                        transactions: 'معاملات',
                        name: 'الاسم',
                        type: 'نوع المعاملة',
                        amount: 'القيمة',
                        no_transactions: 'لا توجد معاملات',
                    }
            },
            VEHICLES: {
                name: 'مركبات',
                single: 'مركبة',
                sewedy_name: 'اتوبيسات',
                sewedy_single: 'اتوبيس',
                columns:
                    {
                        company: 'الشركة',
                        model: 'النوع',
                        bus_company: 'الشركة',
                        bus_model: 'النوع',
                        plate_number: 'رقم اللوحات',
                        color: 'اللون',
                        manufacture_year: 'سنة الصنع',
                        no_vehicles: 'لا توجد مركبات'
                    }
            },
            BOOKINGS: {
                name: 'حجوزات',
                single:
                    'حجز',
                columns:
                    {
                        staion_name: 'اسم المحطة',
                        plug_type_name: 'نوع الشاحن',
                        kws: 'كيلووات ثانية',
                        price: 'السعر',
                        date: 'التاريخ',
                        from: 'من',
                        to: 'إلى',
                        no_bookings: 'لا توجد حجوزات',
                        finished_bookings: 'عمليات الشحن',
                        in_progress_bookings: 'عمليات الشحن الجارية'
                    }
            }
            ,
            DISTRICTS: {
                name: 'مناطق',
                single:
                    'منطقة',
                columns:
                    {
                        description: 'توصيف',
                        name: 'الاسم',
                        country: 'البلد',
                        parent: 'الأب'
                    }
            },
            CHARGING: {
                name: 'شحن',
                single:
                    'شحن',
                columns:
                    {
                        charger: 'رقم تعريف الشاحن',
                        connector: 'الوصلة',
                        user: 'المستخدم',
                        vehicle: 'الاوتوبيس'
                    }
            },
            Gallery: {
                name: 'معرض الصور',
                single: 'صورة',
                images: 'الصور',
                columns: {},
                view_image: 'عرض الصورة',
                change_image: 'إضافة/تعديل صورة',
                upload: 'رفع',
                no_images: 'لا توجد صور بعد',
                no_attached_images: 'لم يتم إلحاق صور للرفع بعد',
                selected_images: 'الصور المختارة',
                no_selected_images: 'لا توجد صور مختارة',
                gallery_images: 'صور المعرض',
                remove: 'حذف',
                save_selected_images: 'حفظ الصور المختارة',
                guide_msg: 'Note :: يجب اختيار صورة {{image}} ثم اضغط {{save_selected_images}}',
                delete_image: 'مسح الصورة',
                delete_image_body: 'هل أنت متأكد؟ سيتم حذف الصورة نهائياً',
                open_gallery: 'فتح معرض الصور'
            }
            ,
            ROLES: {
                name: 'صلاحيات',
                single: 'صلاحية',
                columns:
                    {
                        permissions: 'صلاحيات',
                        select_all: 'اختيار الكل',
                        selected_permission: 'عدد الصلاحيات المختارة',
                    }
            },
            ADMINS: {
                name: 'مشرفين',
                single: 'مشرف',
                columns:
                    {
                        roles: 'صلاحيات'
                    }
            },

            CMS_USERS: {
                name: 'مستخدمي لوحة التحكم الرئيسية',
                single: 'مستخدم لوحة التحكم الرئيسية',
                columns: {}
            },

            Transaction_report: {
                name: 'تقرير عمليات الشحن',
                single: 'تقرير عمليات الشحن',
                details: 'تفصيل عملية الشحن',
            },

            in_progress_transactions: {
                name: 'عمليات شحن جارية',
                single: 'عمليات شحن جارية',
                details: 'تفاصيل عمليات شحن جارية',
            },

            connector_reports: {
                name: 'تقرير الشواحن',
                single: 'تقرير الشواحن'
            },

            Stations_report: {
                name: 'تقرير المحطات',
                single: 'تقرير المحطات'
            },

            users_reports: {
                name: 'تقرير المستخدمين',
                single: 'تقرير المستخدمين',
                driver_name: 'تقرير السائقين',
                driver_single: 'تقرير السائقين',
            },
        },

        BASIC: {
            APP_NAME: 'ADMIN PANAL',
            ADMIN_PANEL: 'لوحة التحكم الرئيسية',
        },

        COMMON: {
            id: 'رقم التعريف',
            in_active: 'غير نشط',
            active: 'نشط',
            activation: 'تنشيط',

            not_default: 'ليس افتراضي',
            default: 'الافتراضي',

            activation_button: 'نشط',
            deactivation_button: 'غير نشط',
            accepted: 'مقبول',
            rejected: 'مرفوض',

            email: 'بريد إلكتروني',
            password: 'كلمة المرور',
            balance: 'رصيد المحفظة',
            remember_me: 'تذكرني',
            password_confirmation: 'تأكيد كلمة المرور',
            forget_password: 'نسيت كلمة المرور؟',
            reset_password: 'تغيير كلمة المرور',
            token: 'رمز التعريف',
            content: 'محتوى',
            name: 'الاسم',
            name_ar: 'الاسم بالعربية',
            name_en: 'الاسم بالانجليزية',
            description_ar: 'التوصيف بالعربية',
            description_en: 'التوصيف بالانجليزية',
            description: 'التوصيف',
            status: 'الحالة',
            latitude: 'خط العرض',
            longitude: 'خط الطول',
            busy: 'مشغول',
            available: 'متاح',
            title: 'عنوان',
            images: 'صور',
            image: 'صورة',
            phone: 'رقم الهاتف',
            address: 'العنوان',
            visitors: 'الزوار',
            visit_number: 'الزيارات',
            clear_form: 'أفرغ الاستمارة',
            no_record_found: 'لا توجد نتائج',
            Add_Button: 'أضف {{name}}',
            Edit_Button: 'عدل {{name}}',
            Delete_Title: 'احذف {{name}}',
            Delete_Button: 'احذف',
            Delete_Body: 'هل أنت متأكد من حذف {{name}} صاحب رقم التعريف {{_id}} ?,  سوف يتم حذفه نهائياً',
            Added_successfully: '{{name}} أضيف بنجاح',
            Edited_successfully: '{{name}} عدل بنجاح',
            Deleted_successfully: '{{name}} حذف بنجاح',
            Details: '{{name}} تفاصيل',
            Item_not_found: '{{name}} لم يتم العثور عليه',
            status_changed: 'تم تغيير الحالة بنجاح',
            old_password: 'كلمة المرور القديمة',
            new_password: 'كلمة المرور الجديدة',
            yes: 'نعم',
            no: 'لا',
            key: 'مفتاح'
        },

        MSG: {},

        stepper: {
            next: 'التالي',
            back: 'السابق',
            error: 'من فضلك أدخل البيانات المطلوبة'
        },

        reports: {
            start_value: 'قراءة البدء',
            stop_value: 'قراءة التوقف',
            start_timestamp: 'توقيت البدء',
            stop_timestamp: 'توقيت التوقف',
            connector_pk: 'رقم الشاحن',
            connector_id: 'معرف الشاحن',
            connector_status: 'حالة الشاحن',
            station_name: 'اسم المحطة',

            time: 'المدة',
            KW: 'كيلووات',
            KW_label: 'الاستهلاك بالكيلووات',
            charge_box: 'الشاحن',
            total_kws: 'اجمالي الكيلووات',
            total_transaction: 'إجمالي المُعاملات',
            user: 'المستخدم',
            charger: 'الشاحن',
            user_name: 'العميل',
            plate_number: 'المركبة',
            transaction_id: 'رقم العملية',
            drivers: 'السائقين',

            transaction_details: {
                transaction_overview: 'مجمل المعاملات',

                transaction_id: 'رقم العملية',
                charge_box_id: 'رقم الشاحن',
                connector_id: 'رقم الشاحن',
                id_tag: 'OCPP ID Tag',

                start_date: 'تاريخ البداية',
                start_value: 'قيمة البدء',
                stop_date: 'تاريخ الانتهاء',
                stop_value: 'قيمة الانتهاء',
                stop_reason: 'سبب الانتهاء',
                event_actor: 'سبب التوقف',
                consumption: 'الاستهلاك بالكيلو وات',
                duration: 'المده',

                meter_values: {
                    connector_pk: 'رقم وصلة الشحن',
                    format: 'الصيغة',
                    location: 'الموقع',
                    measurand: 'القياس',
                    phase: 'المرحلة',
                    reading_context: 'سياق القراءة',
                    transaction_pk: 'رقم المعاملة',
                    unit: 'وحدة',
                    value: 'القيمة',
                    value_timestamp: 'الوقت',
                }


            },

            top_users_by_kws: 'أكثر السائقين من حيث كمية الكهرباء المشحونة',
            top_drivers_by_kws: 'أكثر السائقين من حيث كمية الكهرباء المشحونة',
            top_vehicles_by_kws: 'أكثر المركبات من حيث كمية الكهرباء المشحونة',
            top_users_by_transaction_count: 'اعلي المستخدمين بعدد المعاملات',
            top_drivers_by_transaction_count: 'أكثر السائقين من حيث عدد مرات الشحن',
            top_vehicles_by_transaction_count: 'أكثر المركبات من حيث عدد مرات الشحن',


            filter_from_to: 'اختر الفترة الزمنية من/إلى',
            select: 'اختر',
            number_of_chargers: 'عدد الشواحن',
            total_kws_per_chargers: 'إجمالي الكهرباء المشحونة بالكيلو وات ',

            date: 'التاريخ',
            activation: 'التفعيل',

            bus: 'المركبات',

        },

        transactions: {
            stop_now: 'اقف الان',
            emergency_stop: 'توقف اجباري',
            active_charger: 'الشواحن المتاحة',
            in_active_charger: 'الشواحن الغير متاحة',
        },

        charging_process: {
            start_charging: 'ابدأ الشحن',
            start_new_charging: 'إبدأ شحنه جديده',
            stop_now: 'اوقف الشحن',
            charging: 'الشحن',
            kws: 'كيلووات ثانية',
        }
    }
};

