
export class ModelBase {

	public _id: string;

	constructor(_id:string) {
		this._id = _id;
	}

}
