import { Injectable } from '@angular/core';
import { SystemPermissionsHelperService } from '../../services/Helpers/system.permissions.helper.service';
import { SectionIconsName } from '../../Global/section.icons.name';
import { RoutesName } from '../../Global/routes.name';


@Injectable({
    providedIn: 'root'
})
export class SettingsMenuConfig {

    systemPermissionsHelperService: SystemPermissionsHelperService;

    constructor() {
        this.systemPermissionsHelperService = new SystemPermissionsHelperService();
    }

    private header = { section: 'TITLE', translate: 'MENUS.SETTINGS.TITLE' };

    private section = {
        title: 'MENU.SETTINGS.TITLE',
        root: true,
        translate: 'MENU.SETTINGS.TITLE',
        icon: SectionIconsName.setting(),
        submenu: [
        ]
    };

    public ADMINS = {
        icon: SectionIconsName.admins(),
        title: 'MENU.SETTINGS.MENU.ADMINS',
        translate: 'MENU.SETTINGS.MENU.ADMINS',
        page: RoutesName.admins()
    };



    public menu: any = {
        items: [
        ]
    };


    public checkRoutePermissions() {

        this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.ADMINS);



        this.attachMenu();

    }

    attachMenuItem(permissions, url) {
        let check = this.systemPermissionsHelperService.checkPermissions(permissions);
        if (check) {
            this.attach(url)
        }
    }

    private attach(url) {
        this.section.submenu.push(url)
    }

    private attachMenu() {
        if (this.section.submenu.length) {
            this.menu.items.push(this.header);
            this.menu.items.push(this.section);
        }
    }

    public get configs(): any {
        this.checkRoutePermissions();
        return this.menu.items;
    }

}
