// USA
export const locale = {
    lang: 'en',
    data: {

        MENU: {
            NEW: 'new',
            SHORT_ACCESS: 'Short Access',
            ADD_NEW: 'Add new',
            APPS: 'Apps',
            DASHBOARD: 'Dashboard',
            STATIONS: 'Chargers',
            HOME_STATIONS: 'Home Chargers',
            USERS: 'Users',
            ADMINS: 'Admins',
            DRIVERS: 'Drivers',
            WALLETS: 'Users Wallets',
            STAND: 'standards',
            LOGOUT: 'Logout',
            ANALS: 'Analytics and Charts',
            LANG: {
                AR: 'Arabic',
                EN: 'English',
            },
            SETTINGS: {
                TITLE: 'Settings',
                MENU: {
                    ADMINS: 'Admins',
                    ROLES: 'Roles',
                }
            },
            DATA_TABLE: 'Data Table',
            CATEGORIES: 'Categories',
            PRODUCTS: 'Products',
            STORIES: 'Stories',
            COMMENTS: 'COMMENTS',
            REPORTS: 'Reports',
            REALS: 'Reals',
            REACTIONS: 'Reactions',
            GENERAL: 'GENERAL'
        },

        VALIDATION: {
            INVALID: '{{name}} is not valid',
            REQUIRED: '{{name}} is required',
            REQUIRED_F: '{{name}} is required',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            REQUIRED_FIELD: 'Required field',
            MIN_LENGTH_FIELD: 'Minimum field length:',
            MAX_LENGTH_FIELD: 'Maximum field length:',
            INVALID_FIELD: 'Field is not valid',
        },

        STATISTICS: {
            name: 'Statistics',
            visitors_count: '',
            link: 'Open {{title}} List'
        },

        MENUS: {
            TEMPLATE: {
                TITLE: 'TEMPLATE',
                MENU: {
                    TEMPLATE: 'TEMPLATE',
                },
            },
            STATIONS: {
                NETWORKS: 'Networks',
                DISTRICTS: 'Cities & Districts',
                FACILITIES: 'Facilities',
                PLUGTYPES: 'Plug Types',
                COUNTRIES: 'Countries',
                CHARGING: 'Charging',
                HOME_STATIONS: 'Home Chargers',

            },
            USERS: {
                USERS: 'Users'
            }
        },

        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username'
            },
        },

        Components: {
            item_per_page: 'Item Per Page',
            filter_by: 'Filter by',
            actions: 'Actions',
            all: 'All',
            marked: 'Marked',
            active: 'Active',
            is_active: 'InActive',
            items: 'items',
            id: 'Id',
            options: 'Options',
            Edit: 'Edit',
            Details: 'Details',
            Delete: 'Delete',
            Close: 'Close',
            Search: 'Search',
            ItemNotFound: 'No Result found',
            Images: 'Images',
            status: 'Status',
            date: 'Date',
            Select: 'Select',
            export: 'Export',
            no_image: 'No Image Yet',
            Accept: 'Accept',
            Reject: 'Reject',
            Pending: 'Pending',
            export_excel: 'Export Excel',
            export_pdf: 'Export PDF',

            TEMPLATE: {
                name: 'TEMPLATE',
                single: 'TEMPLATE',
                columns: {
                    TEMPLATE: 'TEMPLATE',
                }
            },

            STATIONS: {
                name: 'Chargers',
                single: 'Charger',
                columns: {
                    description: 'Description',
                    name: 'Name',
                    status: 'Status',
                    price: 'Price',
                    rate: 'Rate',
                    facilities: 'Facilities',
                    district: 'District',
                    network: 'Network',
                    street_location: 'Street Location',
                    charger_type: 'Charger Type',
                    home_charger: 'Home Charger',
                    public_charger: 'Public Charger',
                    stations_time_availability: 'Charger Time Availability',
                    not_available: 'Not Available',
                    available_24_hour: 'Available 24 Hour',
                    every_day_from_to_time: 'Every Day From To Time',
                    stations_time_availability_from: 'Available Time From',
                    stations_time_availability_to: 'Available Time To',
                    station_plug_type: 'Charger Plug Type',
                    km_hours: 'KW per Hour',
                    charge_box_id: 'Charge box id',

                    id_tag: 'Ocpp tag 1',
                    id_tag2: 'Ocpp tag 2',
                }
            },
            HOME_STATIONS: {
                name: 'Home Chargers',
                single: 'home Charger',
                columns: {
                    description: 'Description',
                    name: 'Name',
                    status: 'Status',
                    price: 'Price',
                    rate: 'Rate',
                    facilities: 'Facilities',
                    district: 'District',
                    network: 'Network',
                    street_location: 'Street Location',
                    charger_type: 'Charger Type',
                    home_charger: 'Home Charger',
                    public_charger: 'Public Charger',
                    stations_time_availability: 'Charger Time Availability',
                    not_available: 'Not Available',
                    available_24_hour: 'Available 24 Hour',
                    every_day_from_to_time: 'Every Day From To Time',
                    stations_time_availability_from: 'Available Time From',
                    stations_time_availability_to: 'Available Time To',
                    station_plug_type: 'Charger Plug Type',
                    km_hours: 'km_hours',
                    charge_box_id: 'Charge box id',

                    id_tag: 'Ocpp tag 1',
                    id_tag2: 'Ocpp tag 2',
                }
            },
            NETWORKS: {
                name: 'Networks',
                single: 'Network',
                columns: {
                    description: 'Description',
                    name: 'Name',
                }
            },
            FACILITIES: {
                name: 'Facilities',
                single: 'Facility',
                columns: {
                    name: 'Name',
                }
            },
            PLUGTYPES: {
                name: 'Plug Types',
                single: 'plug Type',
            },
            MODELS: {
                name: 'Models',
                single: 'Model',
                bus_name: 'Bus Models',
                bus_single: 'Bus Model',
            },
            COMPANIES: {
                name: 'Brands',
                single: 'Brand',
                bus_name: 'Bus Brands',
                bus_single: 'Bus Brand',
            },
            CATEGORIES: {
                name: 'Categories',
                single: 'Category',
            },
            PRODUCTS: {
                name: 'Products',
                single: 'Product',
                columns: {
                    category: 'Category'
                }
            },
            USERS: {
                name: 'Users',
                single: 'User',
                sewedy_name: 'Drivers',
                sewedy_single: 'Driver',
                columns: {
                    trips: 'Trips',
                    name: 'Name',
                    no_trips: 'No Trips',
                    from_location: 'From Location',
                    to_location: 'To Location',
                    favorite_stations: 'Favorite Chargers',
                    no_favorite_stations: 'No Favorite Chargers',
                    direction: 'Direction',
                    open_direction: 'Open Direction'

                }
            },
            WALLETS: {
                name: 'Wallets',
                single: 'Wallets',
                columns: {
                    transactions: 'Transactions',
                    name: 'Name',
                    no_transactions: 'No Transactions',
                    type: 'Type',
                    amount: 'Amount',
                }
            },
            VEHICLES: {
                name: 'Vehicles',
                single: 'Vehicle',
                sewedy_name: 'Buses',
                sewedy_single: 'Bus',
                columns: {
                    company: 'Company',
                    model: 'Model',
                    bus_company: 'Bus Brand',
                    bus_model: 'Bus Model',
                    plate_number: 'Plate Number',
                    color: 'Color',
                    manufacture_year: 'Manufacture Year',
                    no_vehicles: 'No Vehicles'
                }
            },
            BOOKINGS: {
                name: 'Bookings',
                single: 'Booking',
                columns: {
                    staion_name: 'Staion Name',
                    plug_type_name: 'Plug Type Name',
                    kws: 'KWS',
                    price: 'Price',
                    date: 'Date',
                    from: 'From',
                    to: 'To',
                    no_bookings: 'No Bookings',
                    finished_bookings: 'Finished Bookings',
                    in_progress_bookings: 'In Progress Bookings'
                }
            },
            DISTRICTS: {
                name: 'Cities & Districts',
                single: 'City & District',
                columns: {
                    description: 'Description',
                    name: 'Name',
                    country: 'Country',
                    parent: 'Parent'
                }
            },

            CHARGING: {
                name: 'Charging',
                single: 'Charging',
                columns: {
                    charger: 'Charge box id',
                    connector: 'Connector',
                    user: 'User',
                    vehicle: 'Buses',
                }
            },


            Gallery: {
                name: 'Gallery',
                single: 'Image',
                images: 'Images',
                columns: {},
                view_image: 'View Image',
                change_image: 'Add / Change imageِِ',
                upload: 'Upload',
                no_images: 'No Gallery Image yet',
                no_attached_images: 'No Images attached yet',
                selected_images: 'Selected Images',
                no_selected_images: 'No Selected Images',
                gallery_images: 'Galley Images',
                remove: 'Remove',
                save_selected_images: 'Save Selected Image',
                guide_msg: 'Note :: you should select {{image}} then Click on {{save_selected_images}}',
                delete_image: 'Delete Image',
                delete_image_body: 'Are you sure ? this Image will be deleted Permanently!',
                open_gallery: 'Open Gallery'
            },
            ROLES: {
                name: 'Roles',
                single: 'Role',
                columns: {
                    permissions: 'Permissions',
                    select_all: 'Select all',
                    selected_permission: 'Number of Selected Permission',
                }
            },
            ADMINS: {
                name: 'Admins',
                single: 'Admin',
                columns: {
                    roles: 'Roles'
                }
            },

            CMS_USERS: {
                name: 'CMS Users',
                single: 'CMS User',
                columns: {}
            },

            Transaction_report: {
                name: 'Transaction report',
                single: 'Transaction report',
                details: 'Transaction details',
            },

            in_progress_transactions: {
                name: 'In Progress Transaction',
                single: 'In Progress Transaction',
                details: 'In Progress Transaction details',
            },

            connector_reports: {
                name: 'Connector report',
                single: 'Connector report',
            },

            Stations_report: {
                name: 'Chargers report',
                single: 'Chargers report'
            },

            users_reports: {
                name: 'Users report',
                single: 'Users report',
                driver_name: 'Drivers report',
                driver_single: 'Drivers report'
            },

            wallets_reports: {
                name: 'Wallets report',
                single: 'Wallet report',
                driver_name: 'Drivers report',
                driver_single: 'Drivers report'
            },

            vehicles_reports: {
                name: 'Vehicle report',
                single: 'Vehicle report',
            },


        },

        BASIC: {
            APP_NAME: 'ADMIN PANAL',
            ADMIN_PANEL: 'Admin Panel',
        },

        COMMON: {
            id: 'ID',
            in_active: 'Inactive',
            active: 'Active',
            activation: 'Activation',
            favorite: 'Favorite',

            not_default: 'Not Default',
            default: 'Default',

            activation_button: 'Active',
            deactivation_button: 'Inactive',
            accepted: 'Accepted',
            rejected: 'Rejected',

            email: 'Email',
            password: 'Password',
            balance: 'Balance',
            remember_me: 'Remember me',
            password_confirmation: 'Password Confirmation',
            forget_password: 'Forget Password ?',
            reset_password: 'Reset Your Password',
            token: 'Token',
            content: 'Content',
            name: 'Name',
            name_ar: 'Name in Arabic',
            name_en: 'Name in English',
            price: 'Price',
            priceAfterDiscount: 'Price After Discount',
            quantity: 'quantity',
            sold: 'sold',
            category: 'Category',
            color: 'Color',
            description_ar: 'Description in Arabic',
            description_en: 'Description in English',
            description: 'Description',
            status: 'Status',
            latitude: 'Latitude',
            longitude: 'Longitude',
            busy: 'Busy',
            available: 'AVAILABLE',
            title: 'Title',
            images: 'Images',
            image: 'Image',
            phone: 'Phone',
            address: 'Address',
            visitors: 'Visitors',
            visit_number: 'Visits',
            clear_form: 'Clear Form',
            no_record_found: 'No Record Found',
            Add_Button: 'Add {{name}}',
            Edit_Button: 'Edit {{name}}',
            Delete_Title: 'Delete {{name}}',
            Delete_Button: 'Delete',
            Delete_Body: 'Are your sure to delete {{name}} that have id {{_id}} ?, This will delete permanently!',
            Added_successfully: '{{name}} added Successfully',
            Edited_successfully: '{{name}} edited Successfully',
            Deleted_successfully: '{{name}} deleted Successfully',
            Details: '{{name}} details',
            Item_not_found: '{{name}} is Not Found or not exists anymore',
            status_changed: 'Status changed successfully',
            old_password: 'Old Password',
            new_password: 'New Password',
            yes: 'Yes',
            no: 'No',
            key: 'Key'
        },

        MSG: {},

        stepper: {
            next: 'Next',
            back: 'Back',
            error: 'Please Enter the Required Data'

        },

        reports: {
            id: 'Id',
            wallet_id: 'Wallet Id',
            amount: 'Amount',
            name: 'User Name',
            created_at: 'Created Time',
            type: 'Transaction Type',
            start_value: 'Start value in KWS',
            stop_value: 'Stop value in KWS',
            start_timestamp: 'Start timestamp',
            stop_timestamp: 'Stop timestamp',
            connector_pk: 'Connector pk',
            connector_id: 'Connector id',
            connector_status: 'Connector status',
            station_name: 'Station Name',
            time: 'Duration',
            KW: 'Kw',
            KW_label: 'Consumptions in Kw',
            charge_box: 'Charger name',
            total_kws: 'Total Kws',
            total_transaction:'Total Transactions',
            user: 'Users',
            vehicle: 'Vehicle',
            charger: 'Charger',
            user_name: 'User',
            plate_number: 'Bus',
            transaction_id: 'Transaction id',
            transaction_details: {
                transaction_overview: 'Transaction overview',

                transaction_id: 'Transaction id',
                charge_box_id: 'Charger name',
                connector_id: 'Connector id',
                id_tag: 'OCPP ID Tag',

                start_date: 'Start Date/Time',
                start_value: 'Start value in KWS',
                stop_date: 'Stop Date/Time',
                stop_value: 'Stop value in KWS',
                stop_reason: 'Stop reason',
                event_actor: 'Stop Event Actor',
                consumption: 'Consumption  in KWS',
                duration: 'Duration',

                meter_values: {
                    connector_pk: 'Connector PK',
                    format: 'format',
                    location: 'location',
                    measurand: 'measurand',
                    phase: 'phase',
                    reading_context: 'reading context',
                    transaction_pk: 'transaction pk',
                    unit: 'unit',
                    value: 'value',
                    value_timestamp: 'value timestamp',
                }
            },
            drivers: 'Drivers',

            top_users_by_kws: 'Top users by kws',
            top_vehicles_by_kws: 'Top Vehicles by kws',
            top_drivers_by_kws: 'Top drivers by kws',
            top_users_by_wallet_balance: 'Top Users by Wallet Balance',
            top_users_by_wallet_transaction_count: 'Top Users by Wallet Transactions',
            top_users_by_transaction_count: 'Top users by transaction count',
            top_vehicles_by_transaction_count: 'Top Vehicles by transaction count',
            top_drivers_by_transaction_count: 'Top drivers by transaction count',

            filter_from_to: 'Filter From/To',
            select: 'Select ',
            number_of_chargers: 'Number of Chargers',
            total_kws_per_chargers: 'Total KWS per Chargers',

            date: 'Date',
            activation: 'Activation',
            bus: 'Bus',

        },

        transactions: {
            stop_now: 'Stop Now',
            emergency_stop: 'Emergency stop',
            active_charger: 'Active Charger',
            in_active_charger: 'In Active Charger',
        },

        charging_process: {
            start_charging: 'Start Charging',
            start_new_charging: 'Start New charging',
            stop_now: 'Stop Now',
            charging: 'Charging',
            kws: 'KWS',
        }

    }
};
