import {ShortAccessMenuConfig} from './header-menues/short.access.menu.config';
import {AddNewMenuConfig} from './header-menues/add.new.menu.config';
import { GeneralMenuConfig } from './aside-menues/generals.menu.config';
import { UsersMenuConfig } from './aside-menues/users.menu.config';
import { SettingsMenuConfig } from './aside-menues/settings.menu.config';
export class MenuConfig {

	// Aside Menu
	generalMenuConfig: GeneralMenuConfig;
	usersMenuConfig: UsersMenuConfig;
	settingsMenuConfig: SettingsMenuConfig;
	// Header Menu
	// shortAccessMenuConfig: ShortAccessMenuConfig;
	addNewMenuConfig:AddNewMenuConfig;

	constructor() {
		this.generalMenuConfig = new GeneralMenuConfig();
		this.usersMenuConfig = new UsersMenuConfig();
		this.settingsMenuConfig = new SettingsMenuConfig();
		// this.shortAccessMenuConfig = new ShortAccessMenuConfig();
		this.addNewMenuConfig = new AddNewMenuConfig();
	}

	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboards',
					root: true,
					alignment: 'left',
					page: '/cms/dashboard',
					translate: 'MENU.DASHBOARD',
				},

			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/cms/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				}
			]
		},
	};

	public get configs(): any {
		this.attachHeaderMenuItems();
		this.attachAsideMenuItems();
		return this.defaults;
	}

	public attachAsideMenuItems(){

		let stations_items = this.generalMenuConfig.configs;
		let users_items = this.usersMenuConfig.configs;
		this.attachAsideMenu(stations_items);
		// this.attachAsideMenu(users_items);

		// Settings Config
		// let settings_items = this.settingsMenuConfig.configs;
		// this.attachAsideMenu(settings_items);
	}

	public attachHeaderMenuItems() {

		// Short Access Config
		// let short_access_items = this.shortAccessMenuConfig.configs;
		// this.attachHeaderMenu(short_access_items);

		// Short Access Config
		let add_menu_items = this.addNewMenuConfig.configs;
		this.attachHeaderMenu(add_menu_items);

	}

	public attachAsideMenu(items){
		items.forEach((item)=>{
			this.defaults.aside.items.push(
				item
			);
		});
	}

	public attachHeaderMenu(items){
		items.forEach((item)=>{
			this.defaults.header.items.push(
				item
			);
		});
	}


}
