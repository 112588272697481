export class RoutesName {

    public static default() {
        return '/';
    }

    public static cms() {
        return 'cms';
    }

    public static admins() {
        return 'admins';
    }


    public static roles() {
        return 'roles';
    }

    public static forgetPassword() {
        return 'forget-password';
    }

    public static profile() {
        return 'profile';
    }

    public static users() {
        return 'users';
    }

    public static categories() {
        return 'categories';
    }

    public static add() {
        return 'add';
    }

    public static show() {
        return 'show';
    }

    public static products() {
        return 'products';
    }

    public static reals() {
        return 'reals';
    }

    public static comments() {
        return 'comments';
    }

    public static stories() {
        return 'stories';
    }  

}
