
export class ExportModel {

	public link: string;

	constructor(link:string) {
		this.link = link;
	}

}
