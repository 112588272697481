import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GalleryInterface} from '../Base-Interface/gallery.Interface';
import {GalleryItem, ImageItem} from '@ngx-gallery/core';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'kt-edit-images',
	templateUrl: './edit-images.component.html',
	styleUrls: ['./edit-images.component.scss']
})
export class EditImagesComponent implements OnInit {

	@Input() form: FormGroup;
	show_gallery: boolean = false;
	@Input() form_control_name: string = null;
	@Input() has_delete_btn = true;
	images: GalleryItem[] = [];

	@Input() selected_images: [] = [];

	isLoadingResults: any = true;
	@Output() image_event = new EventEmitter<[]>();
	@Output() delete_emitter = new EventEmitter<number>();

	constructor(public translateService: TranslateService,
		public dialog: MatDialog) {
		
	}
	

	ngOnInit() {
	}

	



	deleteModal(image) {
		const dialogRef = this.dialog.open(DeleteModalComponent, {
			width: '40rem',
			data: {
				title: this.translateService.instant('Components.Gallery.delete_image'),
				body: this.translateService.instant('Components.Gallery.delete_image_body'),
				name: this.translateService.instant('Components.Gallery.single'),
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.delete(image);
			}
		});
	}
	delete(image: any) {
		if (image.id) { // DELETE UPLOADED IMAGE
			// @ts-ignore
			this.selected_images = this.selected_images.filter(value => {
				// @ts-ignore
				if (value.id) {
					// @ts-ignore
					return image.id != value.id;
				}
				return false;
			});
			this.delete_emitter.emit(image.id);
		}
		else { // DELETE FRESH IMAGE
			// @ts-ignore
			this.selected_images = this.selected_images.filter(value => {
				// @ts-ignore
				if (value.id) {
					return true;
				}
				return value != image;
			});
			this.image_event.emit(this.selected_images);
		}
	}
}
