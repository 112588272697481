// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/guards/auth.guard';
import {GuestGuard} from './core/guards/guest.guard';
import {PermissionsGuard} from './core/guards/permissions.guard';
import {RoutesName} from './core/Global/routes.name';


const routes: Routes = [
    {path: '', canActivate: [GuestGuard], loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
    {
        path: RoutesName.cms(),
        component: BaseComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },

            // admins
            {
                path: RoutesName.categories(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/categories/categories.module')
                    .then(m => m.CategoriesModule)
            },

            {
                path: RoutesName.products(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/products/products.module')
                    .then(m => m.ProductsModule)
            },

            {
                path: RoutesName.reals(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/reals/reals.module')
                    .then(m => m.RealsModule)
            },
            {
                path: RoutesName.stories(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/stories/stories.module')
                    .then(m => m.StoriesModule)
            },
            {
                path: RoutesName.comments(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/comments/comments.module')
                    .then(m => m.CommentsModule)
            },
            {
                path: RoutesName.users(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/users/users.module')
                    .then(m => m.UsersModule)
            },
            {
                path: RoutesName.admins(), // <= Page URL ,
                loadChildren: () => import('./views/pages/modules/settings/admins/admins.module')
                    .then(m => m.AdminsModule)
            },
            
        ]
    },

    {
        path: 'error/:type', component: ErrorPageComponent,
        data: {
            'type': 'error-v1',
            'code': 403,
            'title': '403... Access forbidden',
            'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
        }
    },
    {path: '**', redirectTo: 'error/error-v1', pathMatch: 'full'},


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
