import {Injectable} from '@angular/core';
import {SystemPermissionsHelperService} from '../../services/Helpers/system.permissions.helper.service';
import {SectionIconsName} from '../../Global/section.icons.name';
import {RoutesName} from '../../Global/routes.name';
import {environment} from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UsersMenuConfig {

    systemPermissionsHelperService: SystemPermissionsHelperService;

    constructor() {
        this.systemPermissionsHelperService = new SystemPermissionsHelperService();
    }

    private header = {section: 'Stations', translate: 'MENU.STATIONS'};

    private section = {
        title:'MENU.USERS',
        root: true,
        translate:'MENU.USERS',
        icon: SectionIconsName.users(),
        submenu: []
    };

    public USERS = {
        icon: SectionIconsName.users(),
        title: 'MENU.USERS',
        translate: 'MENU.USERS',
        page: RoutesName.users()
    };



    public menu: any = {
        items: []
    };


    public checkRoutePermissions() {

        this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.USERS);
        this.attachMenu();

    }

    attachMenuItem(permissions, url) {
        let check = this.systemPermissionsHelperService.checkPermissions(permissions);
        if (check) {
            this.attach(url);
        }
    }

    private attach(url) {
        this.section.submenu.push(url);
    }

    private attachMenu() {
        if (this.section.submenu.length) {
            this.menu.items.push(this.header);
            this.menu.items.push(this.section);
        }
    }

    public get configs(): any {
        this.checkRoutePermissions();
        return this.menu.items;
    }

}
