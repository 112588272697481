
export class SectionIconsName {

	public static cms(){
		return 'cms';
	}

	public static admins() {
		return 'fas fa-user-shield';
	}


	public static forgetPassword() {
		return 'forget-password';
	}

	public static add(){
		return 'flaticon2-add';
	}

	public static users() {
		return 'fas fa-user-friends';
	}

	public static categories() {
		return 'flaticon-earth-globe';
	}

	public static products() {
		return 'flaticon-earth-globe';
	}

	public static reals() {
		return 'flaticon-earth-globe';
	}

	public static stories() {
		return 'flaticon-earth-globe';
	}


	public static comments() {
		return 'flaticon-earth-globe';
	}


	public static reactions() {
		return 'flaticon-earth-globe';
	}

	public static setting(){
		return 'flaticon-cogwheel-2';
	}

	public static general(){
		return 'fas fa-list';
	}

	public static reports(){
		return 'flaticon-diagram';
	}



}
