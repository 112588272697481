import {Injectable} from '@angular/core';
import {SystemPermissionsHelperService} from '../../services/Helpers/system.permissions.helper.service';
import {SectionIconsName} from '../../Global/section.icons.name';
import {RoutesName} from '../../Global/routes.name';
import {environment} from '../../../../environments/environment';


@Injectable({
	providedIn: 'root'
})
export class GeneralMenuConfig {

	systemPermissionsHelperService: SystemPermissionsHelperService;

	constructor() {
		this.systemPermissionsHelperService = new SystemPermissionsHelperService();
	}

	private header = { section: 'GENERAL', translate: 'MENU.GENERAL'};

	private section = {
		title: 'MENU.GENERAL',
		root: true,
		translate: 'MENU.GENERAL',
		icon: SectionIconsName.general(),
		submenu: [
		]
	};

	public CATEGORIES = {
		// icon: SectionIconsName.categories(),
		title: 'MENU.CATEGORIES',
		translate: 'MENU.CATEGORIES',
		page: RoutesName.categories()
	};

	public PRODUCTS = {
		// icon: SectionIconsName.products(),
		title: 'MENU.PRODUCTS',
		translate: 'MENU.PRODUCTS',
		page: RoutesName.products()
	};

	// public REALS = {
	// 	// icon: SectionIconsName.reals(),
	// 	title: 'MENU.REALS',
	// 	translate: 'MENU.REALS',
	// 	page: RoutesName.reals()
	// };

	// public STORIES = {
	// 	// icon: SectionIconsName.stories(),
	// 	title: 'MENU.STORIES',
	// 	translate: 'MENU.STORIES',
	// 	page: RoutesName.stories()
	// };

	// public COMMENTS = {
	// 	// icon: SectionIconsName.comments(),
	// 	title: 'MENU.COMMENTS',
	// 	translate: 'MENU.COMMENTS',
	// 	page: RoutesName.comments()
	// };
	



	public menu: any = {
		items: [
		]
	};


	public checkRoutePermissions(){

		this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.CATEGORIES);
		this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.PRODUCTS);
		// this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.COMMENTS);

		// this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.REALS);

		// this.attachMenuItem(['ADMINS_ADMINS_INDEX'], this.STORIES);

		this.attachMenu();

	}

	attachMenuItem(permissions, url){
		let check = this.systemPermissionsHelperService.checkPermissions(permissions);
		if (check){
			this.attach(url)
		}
	}

	private attach(url){
		this.section.submenu.push(url)
	}

	private attachMenu(){
		if (this.section.submenu.length){
			this.menu.items.push(this.header);
			this.menu.items.push(this.section);
		}
	}

	public get configs(): any {
		this.checkRoutePermissions();
		return this.menu.items;
	}

}
